<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-plus"></i> เพิ่มสินค้า</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(addHandler)">
              <form-product :item="item"
                            :tags="tags"
                            :funcs="funcs"
                            :action="action"
                            :suppliers="suppliers"
                            :stores="stores"
                            :imageDeletes="imageDeletes"
                            @selected-supplier="getValueSuppliers"
                            @selected-store="getValueStores"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/product'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button variant="primary" type="submit" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formProduct from './form.product';
import * as scripts from '../../../util/scripts';
import StringUtils from '../../../util/StringUtils';
export default {
  name: 'product-view-page-product-add',
  data () {
    return {
      permission:{
        appSlug: 'product',
        actionData: {}
      },
      item: {
        id: '',
        cat_id: '',
        code: '',
        name: '',
        status: '',
        imgs: [],
        brief: '',
        detail: '',
        color: '',
        size: '',
        tags: []
      },
      imageDeletes: [],
      suppliers: [],
      stores: [],
      action: 'add',
      tags: [],
      funcs: scripts.funcs
    }
  },
  methods: {
    async addHandler () {
      const file = this.item.imgs;
      delete this.item.imgs;
      if(this.item.tags&&this.item.tags.length>0){
        this.item.tagDatas = JSON.stringify(this.item.tags);
      }else{
        this.item.tagDatas = "";
      }
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.postData("/ab_product", this.item);
      if(result&&result.status.code=="200"){
        if(file&&file.length>0){
          const s3Auth = await this.HttpServices.authenS3();
          if(s3Auth._info){
            const medias = [];
            const accessToken = s3Auth._info.secret.accessToken;
            for(const f of file){
       


              let params = new FormData();
              params.append("file", f);
              params.append("title", f.name);
              params.append("file_category", 'products');
              const resultMedia = await this.HttpServices.postFormDataS3(params, accessToken);
              if(resultMedia.media&&resultMedia.media.length>0){
                const media = resultMedia.media[0];
                const mediaUpload = {
                  id: media.id,
                  original_name: media.original_name,
                  mime_type: media.mime_type,
                  size: media.size,
                  url: media.url
                };

                params = new FormData();
                const thumbFile = await StringUtils.resizeBlobToFile({file: f, maxSize: 200});
                params.append("file", thumbFile);
                params.append("title", thumbFile.name);
                params.append("file_category", 'products');
                let resultThumb = await this.HttpServices.postFormDataS3(params, accessToken);
                if(resultThumb.media&&resultThumb.media.length>0){
                  const mediaThumb = resultThumb.media[0];
                  mediaUpload.id_t = mediaThumb.id;
                  mediaUpload.original_name_t = mediaThumb.original_name;
                  mediaUpload.mime_type_t = mediaThumb.mime_type;
                  mediaUpload.size_t = mediaThumb.size;
                  mediaUpload.url_t = mediaThumb.url;

                  params = new FormData();
                  const mediaThumbM = await StringUtils.resizeBlobToFile({file: f, maxSize: 400});
                  params.append("file", mediaThumbM);
                  params.append("title", mediaThumbM.name);
                  params.append("file_category", 'products');
                  let resultThumbM = await this.HttpServices.postFormDataS3(params, accessToken);
                  if(resultThumbM.media&&resultThumbM.media.length>0){
                    const mediaThumbM = resultThumbM.media[0];
                    mediaUpload.id_m = mediaThumbM.id;
                    mediaUpload.original_name_m = mediaThumbM.original_name;
                    mediaUpload.mime_type_m = mediaThumbM.mime_type;
                    mediaUpload.size_m = mediaThumbM.size;
                    mediaUpload.url_m = mediaThumbM.url;


                    
                  }



                }

               



                medias.push(mediaUpload);
              }
            }
            const params = {media: JSON.stringify(medias)};
            await this.HttpServices.postFormData(`/ab_product/upload/${result.data}`, params);
          }
        }
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/product');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
      this.SpinnerService.hideLoader();
    },
    onBackHandler(){
      this.$router.push("/product");
    },
    async getTags(){
      this.tags = await this.HttpServices.getMasterData("/master/getTags");
    },
    getValueSuppliers (dataRows) {
      this.item.suppliers = dataRows;
    },
    getValueStores (dataRows) {
      this.item.stores = dataRows;
    }
  },
  components: {
    formProduct
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    await this.getTags();
  }
}
</script>
